import React from 'react';

import styled from "styled-components";
import { SectionStyled, HeaderH3, HeaderH3Description } from '../../../components/commons-styles';
import { palePurple, baseWhite, smallBreapoint } from '../../../components/styles-settings';
import ProjectMap from './Map';
import { EMAIL, EMAILHREF } from '../../../../settings';


const MapWrapper = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  margin-bottom: 1.5rem;

  @media (min-width: ${`${smallBreapoint}px`}) {
    width: 65%;
  }
`;

const ContactContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ContactDetails = styled.div`
  width: 100%;

  @media (min-width: ${`${smallBreapoint}px`}) {
    width: 35%;
  }
`;

const ContactDetailsList = styled.ul`
  display: inline-block;
  text-align: center;
  @media (min-width: ${`${smallBreapoint}px`}) {
    text-align: left;
  }
`;

const ContactDetailColor = styled.li`
  color: ${palePurple}
  font-size: 1.2rem;
`;

const ContactDetailSpace = styled.li`
  margin-bottom: 1rem;
`;

const ContactEmptyLink = styled.a`
  color: ${baseWhite};
  text-decoration: none;

`;

function SectionContact() {
  return(
  <SectionStyled
    id="kontakt"
    breakpoint="small"
  >
    <HeaderH3>Kontakt</HeaderH3>
    <HeaderH3Description></HeaderH3Description>
    <ContactContent>
      <MapWrapper>
        <ProjectMap />
      </MapWrapper>
      <ContactDetails>
        <ContactDetailsList>
          {/* Sp. z.o.o */}
          <ContactDetailColor>Dysplan</ContactDetailColor>
          <li>ul. Ciepłownicza 54,</li>
          <ContactDetailSpace>31-587 Kraków</ContactDetailSpace>
          <ContactDetailSpace>Biuro - <ContactEmptyLink href="tel:12 36 296 36" rel="nofollow"> tel. 12 36 296 36</ContactEmptyLink></ContactDetailSpace>
          <ContactDetailSpace>Piotr - <ContactEmptyLink href="tel:533 334 561" rel="nofollow"> tel. 533 334 561</ContactEmptyLink></ContactDetailSpace>
          <ContactDetailSpace>Artur  - <ContactEmptyLink href="tel:533 334 560" rel="nofollow"> tel. 533 334 560</ContactEmptyLink></ContactDetailSpace>
          <li>email: <ContactEmptyLink href={EMAILHREF}>{EMAIL}</ContactEmptyLink></li>
          <li>fax: 12 36 296 37</li>
          <li>biuro czynne: pon - pt od 8 - 16</li>

        </ContactDetailsList>
      </ContactDetails>
    </ContactContent>
  </SectionStyled>)
}

export default SectionContact
