import React, { Component } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';


const latitudeX = 50.0488514;
const latitudeY = 20.0120135;

class ProjectMap extends Component {
  render() {
    const { options } = this.props;

    if (typeof window !== 'undefined') {
      return (
        <Map
          style={{ height: "400px", width: "100%" }}
          zoom={15}
          center={[latitudeX, latitudeY]}
          {...options}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
          />
          <Marker
            position={[latitudeX, latitudeY]}
            title={'Siedziba firmy Dysplan w Krakowie'}
            alt={'Siedziba firmy Dysplan w Krakowie'}
          />
        </Map>
      )
    }
    return null
  }
}

export default ProjectMap;