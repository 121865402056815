import React from 'react';
import styled from "styled-components";
import { faGavel } from '@fortawesome/fontawesome-free-solid/faGavel';
import { faClock } from '@fortawesome/fontawesome-free-solid/faClock';
import { faComment } from '@fortawesome/fontawesome-free-solid/faComment';
import { faHandshake } from '@fortawesome/fontawesome-free-solid/faHandshake';
import Link from 'gatsby-link';

import { palePurple } from '../../../components/styles-settings';
import { SectionStyled, HeaderH2 } from '../../../components/commons-styles';
import TileIcon from '../../../components/Tile/TileIcon';
import urls from '../../../data/urls';


const dataAboutUs = [
  {
    id: 1,
    title: "Solidne Wykonanie",
    icon: faGavel,
    text: 'Cenimy opinie klientów, dlatego stosujemy wysokiej jakości plandeki, trwałe w każdych warunkach',
  },
  {
    id: 2,
    title: "Terminowość",
    icon: faClock,
    text: 'Zawsze na czas okleimy Twój samochód',
  },
  {
    id: 3,
    title: "Profesjonalizm",
    icon: faHandshake,
    text: 'Do każdej reklamy - baner, plandeka, kaseton, podchodzimy z pełnym zaangażowaniem',
  },
  {
    id: 4,
    title: "Elastyczność",
    icon: faComment,
    text: 'Żadnych wyzwań się nie boimy, nietypowe kształty zadaszenia, okrycia basenu z plandeki.',
  },
];

const HeaderDescriptionWrapper = styled.article`
  margin-bottom: 3rem;
  font-size: 1.25rem;
`;

const HeaderH3Description = styled.p`
  margin-bottom: 1rem;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
`;

const StyledLink = styled(Link)`
  color: ${palePurple};
`;

const SectionAboutUs = () => (
  <SectionStyled
    id="o_nas"
    breakpoint="small"
  >
    <HeaderH2>O naszej firmie - Dysplan</HeaderH2>
    <HeaderDescriptionWrapper>
    <HeaderH3Description>Jesteśmy firmą działającą w branży reklamowej. Zajmujemy się szeroko pojętą obróbką materiałów z
      tworzyw sztucznych oraz identyfikacją wizualną firm. Wykonujemy między innymi plandeki na
      samochody ciężarowe, namioty, zadaszenia, banery, oklejamy samochody, drukujemy ulotki, wizytówki i
      wiele innych.
    </HeaderH3Description>
    <HeaderH3Description>
      Kilkudziesięcioletnie doświadczenie w branży sprawiło, że o reklamie wiemy niemal wszystko, a
informacje udzielane Klientom są zawsze wyczerpujące.
    </HeaderH3Description>
    <HeaderH3Description>
      Naszym priorytetem jest terminowe i rzetelne wykonywanie powierzonych nam zleceń. Do każdego
zadania podchodzimy w sposób indywidualny, co zaowocowało rzeszą zadowolonych z naszych usług
Klientów. Nie boimy się nietypowych zleceń, traktujemy je jako wyzwanie, dzięki któremu możemy
nauczyć się nowych rzeczy.
    </HeaderH3Description>
    <HeaderH3Description>
      Zachęcamy Państwo do bliższego zapoznania się z naszą <StyledLink to={urls.get('oferta')}>ofertą</StyledLink>.
    </HeaderH3Description>
      </HeaderDescriptionWrapper>

      <List>
        {
          dataAboutUs.map((data) =>  (
            <TileIcon
              key={data.id}
              title={data.title}
              icon={data.icon}
              text={data.text}
            />
          ))
        }
      </List>
  </SectionStyled>
);

export default SectionAboutUs
