import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import { minBreakpoint } from '../../styles-settings';


const SlideStyled = styled.div`
  display: flex;
  height: 200px;
  max-height: 200px;
  object-fit: cover;

  @media (min-width: ${`${minBreakpoint}px`}) {
    height: 270px;
    max-height: 270px;
  }
`;

const Img = styled.img`
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`;

class Slide extends React.Component {
  render() {
   const { src, alt, title } = this.props;
    return (
      <SlideStyled>
        <Img src={src} alt={alt} title={title} />
      </SlideStyled>
    );
  }
}

export default Slide;