import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slide from './Slide';


class BasicSlider extends React.Component {
  render() {
    const { dataSlides } = this.props;
    const settings = {
      dots: false,
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        {
          dataSlides.imageItems.map((slide) => {
            if (!slide || !slide.image || !slide.image.url) return null;
            return(
              <Slide
                key={slide.id}
                src={slide.image.url}
                alt={slide.alt}
                title={slide.title}
              />
            );
          })
        }
      </Slider>
    );
  }
}

export default BasicSlider;