import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import BasicSlider from '../../../components/BasicSlider';
import { SectionStyled, HeaderH3, HeaderH3Description, fancyBackground } from '../../../components/commons-styles';
import Overlay from '../../../components/Overlay';
import Container from '../../../components/Container';


const SliderWrapper = styled.div`
  padding: 0.5rem 3rem;
  margin-bottom: 1.5rem;
  
  .slick-slider {
    height: 100%;
  }
  
  .slick-list {
    height: 100%;
  }
  
  .slick-slider {
    height: 100%;
  }
  
  .slick-slide {
    padding: 1rem;
  }
`;

const BasicSliderStyled = styled(BasicSlider)``;

const Fancybackground = styled.div`
  ${fancyBackground};
`;

const SectionGalleryStyled = styled(SectionStyled)`
  position: relative;
`;

const SectionGallery = () => (
  <Fancybackground>
    <Overlay>
      <SectionGalleryStyled
        id="galeria"
      >
        <Container>
          <HeaderH3>Galeria</HeaderH3>
          <HeaderH3Description>Kilka przykładowych naszych realizacji</HeaderH3Description>
          <SliderWrapper>
            <StaticQuery
              query={graphql`
                query HomepageSlider {
                  dysplan {
                    gallery(where: {slug: "homepage"}) {
                      imageItems {
                        id
                        image {
                          url
                        }
                        alt
                        title
                      }
                    }
                  }
                }
              `}
              render={(data) => {
                return (<BasicSliderStyled dataSlides={data.dysplan.gallery} />);
              }}
            />
          </SliderWrapper>
        </Container>
      </SectionGalleryStyled>
    </Overlay>
  </Fancybackground>
);

export default SectionGallery
