import React from 'react';
import CountUp from 'react-countup';


class Counter extends React.PureComponent {
  render() {
    const {start, end, duration, startCount } = this.props;
    return (
      <CountUp
        start={start}
        end={startCount ? end : start}
        duration={duration}
        useEasing
      />
    )
  }
}

export default Counter;