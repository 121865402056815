import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { palePurple, minBreakpoint, normalBreakpoint } from '../styles-settings';



const Root = styled.li`
  width: 100%;
  
  @media (min-width: ${`${minBreakpoint}px`}) {
    width: 50%;
  }
  
  @media (min-width:  ${`${normalBreakpoint}px`}) {
    width: 25%;
  }
  
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  display: block;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: ${palePurple};
`;

const IconWrapper = styled.span`
  display: block;
  margin-bottom: 0.5rem;
  max-height: 31px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`;

const Text = styled.p`

`;

class TileIcon extends React.PureComponent {
  render() {
    const { title, text, icon } = this.props;
    return (
      <Root >
        <IconWrapper><Icon icon={icon}/></IconWrapper>
        <Title>{title}</Title>
        <Text> {text} </Text>
      </Root>
    );
  };
  
}

export default TileIcon
