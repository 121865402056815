import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import Homepage from '../container/Homepage';
import { FULL_PAGE_URL } from '../../settings';


const IndexPage = () => (
  <Layout>
    <Helmet link={[{ rel: 'canonical', href: `${FULL_PAGE_URL}/` }]} />
    <Homepage />
  </Layout>
);

export default IndexPage;
