import React from 'react';
import styled from "styled-components";
import EventListener, {withOptions} from 'react-event-listener';

import { basePurple } from '../../../components/styles-settings';
import { SectionStyled } from '../../../components/commons-styles';

import TileCounter from "../../../components/Tile/TileCounter";


const dataCredentials = [
  {
    id: 1,
    title: "Wbitych oczek",
    start: 125000,
    end: 138650,
    duration: 5,
  },
  {
    id: 2,
    title: "Zadowolonych klientów",
    start: 1180,
    end: 1380,
    duration: 5,
  },
  {
    id: 3,
    title: "Wykorzystanych metrów plandek",
    start: 12500,
    end: 14350,
    duration: 5,
  },
  {
    id: 4,
    title: "Wykonanych projektów reklam",
    start: 1750,
    end: 1923,
    duration: 5,
  },
  
];

const HeaderH3 = styled.h3`
  display: inline-block;
  font-size: 2em;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: -0.3rem;
    right: -0.3rem;
    height: 0.15rem;
    background: ${basePurple};
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

class SectionCredentials extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      startCount: false,
    }
  }
  
  handleScroll = (e) => {
    const { startCount } = this.state;
    const containerPositionY = this.containerRef.current.getBoundingClientRect().top;
    const containerHeight = this.containerRef.current.getBoundingClientRect().height;
    const windowHeight = window.innerHeight;
    const windowOffsetY = window.pageYOffset;
    if (((windowOffsetY - windowHeight - 200 > containerPositionY && containerPositionY > 0) ||
      (containerPositionY <= 0 && ((containerPositionY + containerHeight) > 0))) &&
      !startCount
    ) {
      this.setState({
        startCount: true,
      })
    }
  };
  
  render() {
    const { startCount } = this.state;
    return (
      <SectionStyled
        id="credentials"
        breakpoint="small"
        ref={this.containerRef}
      >
        <EventListener
          target="window"
          onResize={this.handleResize}
          onScroll={withOptions(this.handleScroll, {passive: true, capture: false})}
        />
        <HeaderH3>Fakty i liczby</HeaderH3>
        <List>
          {
            dataCredentials.map((data) => (
              <TileCounter
                key={data.id}
                title={data.title}
                start={data.start}
                end={data.end}
                duration={data.duration}
                startCount={startCount}
              />
            ))
          }
        </List>
      </SectionStyled>
    );
  }
}

export default SectionCredentials

