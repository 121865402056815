import React from 'react';
import Helmet from 'react-helmet';

import Jumbotron from '../../components/Jumbotron';
import SectionAboutUs from './SectionAboutUs';
import SectionOffer from './SectionOffer';
import SectionContact from './SectionContact';
import SectionGallery from './SectionGallery';
import SectionCredentials from './SectionCredentials';
import fullpageImg from '../../images/car.jpg';


const IndexPage = () => (
  <React.Fragment>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "name": "Dysplan",
          "description": "Dysplan firma zajmująca się wykonaniem plandek, banerów, reklam w Polsce i zagranicą. Tworząca produkty z solidnej jakości materiałów i nie bojąca się podejmować nowych i ciekawych projektów",
          "url": "https://dysplan.info.pl/",
          "logo": {
            "@type": "ImageObject",
            "url": "https://d33wubrfki0l68.cloudfront.net/3f1acee9820db3cc24c7ce629392d6600db36f31/abcb4/static/logo-80153ca3d1b799b0c1cea5fe2495e71d.png",
            "height": "84",
            "width": "270"
          },
          "image": {
            "@type": "ImageObject",
            "url": "https://d33wubrfki0l68.cloudfront.net/3f1acee9820db3cc24c7ce629392d6600db36f31/abcb4/static/logo-80153ca3d1b799b0c1cea5fe2495e71d.png",
            "height": "84",
            "width": "270"
          },
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+48 12 36 296 36",
            "contactType": "Customer Service",
            "areaServed": "PL"
          },{
            "@type": "ContactPoint",
            "telephone": "+48 533 334 561",
            "contactType": "Customer Service",
            "areaServed": "PL"
          },{
            "@type": "ContactPoint",
            "telephone": "+48 533 334 560",
            "contactType": "Customer Service",
            "areaServed": "PL"
          }],
          "email": "biuro@dysplan.pl",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "Poland",
            "addressLocality": "Kraków",
            "postalCode": "31-587",
            "streetAddress": "Ciepłownicza 54,"
          },
          "sameAs": [
            "https://www.facebook.com/Dysplan/",
            "https://www.instagram.com/dysplanpiotrsurma/"
          ]
        }`}
      </script>
    </Helmet>
    <Jumbotron
      srcImage={fullpageImg}
      minHeight="500px"
      minHeightMobile="500px"
      heightJumb="auto"
      header="Dysplan - świat plandek"
      subHeader="Nie obiecujemy cudów, nie zrealizujemy Twojego zlecenia ”na wczoraj”, ale na pewno
          wykonamy je w terminie."
    />
    <SectionAboutUs />
    <SectionOffer />
    <SectionCredentials />
    <SectionGallery />
    <SectionContact />
  </React.Fragment>
);

export default IndexPage;
