import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';

import Container from '../../../components/Container';
import { fancyBackground, SectionStyled, HeaderH3, HeaderH3Description } from '../../../components/commons-styles';
import Overlay from '../../../components/Overlay';
import urls from '../../../data/urls';
import { darkPurple, smallBreapoint, baseWhite } from '../../../components/styles-settings';
import Offers from './Offers';


const Fancybackground = styled.div`
  ${fancyBackground};
`;

const SectionOfferStyled = styled(SectionStyled)`
  position: relative;
`;

const StyledLink = styled(Link)`
  padding: 0.75rem 1.5rem;
  background: ${darkPurple};
  color: ${baseWhite};
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: block;

  @media (min-width: ${`${smallBreapoint}px`}) {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    color: ${baseWhite};
  }
`;

const SectionOffer = () => (
  <Fancybackground>
    <Overlay>
      <SectionOfferStyled
        id="offer"
      >
        <Container breakpoint="small">
          <HeaderH3>Oferta</HeaderH3>
          <HeaderH3Description>Specjalizujemy się w plandekach, oklejaniu samochodów, banerach, kasetonach, ale również
            nie boimy się nietypowych zleceń. Poniżej znajdą Państwo kilka naszych ofert.</HeaderH3Description>
        </Container>
        <Container>
          <Offers first={8} />
          <StyledLink to={urls.get('oferta')}>Zobacz całą ofertę</StyledLink>
        </Container>
      </SectionOfferStyled>
    </Overlay>
  </Fancybackground>
);

export default SectionOffer;
