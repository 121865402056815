import React from 'react';
import styled from "styled-components";
import PieChart from 'react-minimal-pie-chart';

import { palePurple, minBreakpoint, normalBreakpoint } from '../styles-settings';
import Counter from '../Counter';


const Root = styled.li`
  width: 100%;

  @media (min-width: ${`${minBreakpoint}px`}) {
    width: 50%;
  }

  @media (min-width:  ${`${normalBreakpoint}px`}) {
    width: 25%;
  }

  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  
  > span:first-of-type {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 1rem;
  }
`;

const Title = styled.span`
  display: block;
  font-size: 1.25rem;
  margin: 1rem 0;
  color: ${palePurple};
`;

const CounterStyled = styled.div`
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CounterWrapper = styled.div`
  position: relative;
`;

const PieChartStyled = styled(PieChart)`
  height: 150px;
`;

class TileCounter extends React.PureComponent {
  render() {
    const { title, start, end, duration, startCount } = this.props;
    return (
      <Root >
        <CounterWrapper>
          <PieChartStyled
            lineWidth={15}
            rounded
            data={[
              { value: 10, color: '#E38627' },
              { value: 15, color: '#C13C37' },
              { value: 20, color: '#6A2135' },
            ]}
          />
          <CounterStyled>
            <Counter
              start={start}
              end={end}
              duration={duration}
              startCount={startCount}
            />
          </CounterStyled>
        </CounterWrapper>
        <Title>{title}</Title>
      </Root>
    );
  };
  
}

export default TileCounter
